<template>
  <div class="h-96 mx-5 overflow-y-auto">
    <div
      class="
        sticky
        top-0
        z-10
        p-2
        text-sm
        font-medium
        border
        bg-white
        border-brand-red
      "
    >
      <h2 class="font-bold">Aktuelle Uhrzeit</h2>
      <h3>
        {{ new Date(now).toLocaleDateString("de-DE") }} -
        {{ new Date(now).toLocaleTimeString("de-DE") }}
      </h3>
    </div>

    <div class="mt-6">
      <h3 class="font-bold">Programm</h3>
      <p>Hier finden Sie eine Übersicht zu unserem Live-Programm.</p>
    </div>
<div class="p-4 border border-green bg-grey my-4" v-if="success">Danke für Ihre Bewertung</div>
    <div v-for="(stream, index) in streams" :key="index">
      <div v-if="stream.timestamp > now">
        <div class="p-4 mt-2 border-l-4 bg-grey border-avarto">
          <!--        <p class="flex">-->
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div>
            {{ stream.title }}:<br>
            {{ new Date(stream.timestamp).toLocaleDateString("de-De") }} -
            {{ new Date(stream.timestamp).toLocaleTimeString("de-De") }}
          </div>
          <!--          </p>-->
        </div>
      </div>
      <div
        v-else-if="stream.timestamp + stream.duration > now"
        class="mt-2 rounded-md bg-gray-50"
      >
        <p class="flex p-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
          Live: {{ stream.title }}
        </p>
      </div>
      <div  v-else-if="
          stream.timestamp + stream.duration < now &&
          now < stream.timestamp + stream.duration + 60000
        " 
        class="mt-2 rounded-md bg-gray-50 border border-grey bg-grey">
        <div class=p-4>
        {{stream.title}}:
      </div>
      <div class="flex p-4">
          
          <button class="border-green border rounded-sm px-6 py-3 mr-2 flex text-green" @click="valuate(stream, index, 1)"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
</svg>gut</button>
          <button class="border-avarto flex rounded-sm border px-6 py-3 mr-2 text-avarto" @click="valuate(stream, index, 0)"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5" />
</svg>schlecht</button>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  props: {
    streams: [],
  },
  mounted() {
    (this.uid = firebase.auth().currentUser.uid),
      (this.now = setInterval(() => {
        this.now = Date.now();
      }, 1000));
  },
  methods: {
    valuate(stream, index, valuation) {
      const valuationInput = {
        uid: this.uid,
        index: index.toString(),
        keynote: stream.title,
        valuation: valuation,
      };
      this.success = true; 
      firebase
        .functions()
        .httpsCallable("valuation")(valuationInput)
        .then(async (retData) => {
          console.log(retData);
          await this.timeout(5000);
          this.success = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  data: () => ({
    now: Date.now(),
    uid: "",
    success: false
  }),
};
</script>

<style scoped></style>
